<template>
    <div class="footer_box">
        <div class="footer_container">
            <div class="left_box">
                <div class="left_text">
                    <div class="footer_title">
                        <div v-for="(item, index) in footerList" :key="index" :class="['title_items',index==1?'cursor_pinter':null]" @click="fnOpen(item)">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="record_inner">
                      <div class="record_inner_text">© 新隆福2022 - 2042   京ICP备2022020823号-1</div>
                      <div class="record_inner_text">
                          <img src="https://www.ncsti.gov.cn/images/foot-newjh.png" alt="">
                          <span style="position:relative;top: -4px;left: 4px">京公网安备 11010102006488号</span>
                      </div>
                      <div>联系我们 010-84036542</div>
                    </div>
                </div>
                <div class="mid_box">
                    <img
                    src="@/assets/index/bottom_logo.png"
                    class="mid_img"
                    />
                </div>
                <!-- <div class="record_inner">京ICP备 2022020823号-1</div> -->
            </div>
            <div class="right_box">
                <div v-for="(item, index) in codeList" :key="index" :class="['code_item','code_item'+index]">
                    <img :src="item.img" :alt="item.name" class="item_img">
                    <div class="item_name">{{ item.name }}</div>

                    <div class="floating_window gzh_window" v-if="index == 0">
                        <img
                            src="@/assets/app_download/GZH.jpeg"
                            class="window_img"
                        />
                    </div>

                    <div class="floating_window app_window" v-else>
                        <div class="img_box">
                            <img
                                src="@/assets/app_download/huawei.png"
                                class="window_img"
                            />
                            <span>华为</span>
                        </div>

                        <div class="img_box">
                            <img
                                src="@/assets/app_download/apple.png"
                                class="window_img"
                            />
                            <span>苹果</span>
                        </div>

                        <div class="img_box">
                            <img
                                src="@/assets/app_download/xiaomi.png"
                                class="window_img"
                            />
                            <span>小米</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

let footerList = ref([
    {
        id: 1,
        name: '友情链接'
    },
    {
        id: 2,
        name: '国资公司'
    },
])

let codeList = ref([
    {
        id: 1,
        name: '微信公众号',
        img: require("@/assets/index/wechat.png")
    },
    {
        id: 2,
        name: 'app下载',
        img: require("@/assets/index/app.png")
    }
])
function fnOpen(item){
  if(item.id==2){
    window.open('https://www.bsam.com.cn/')
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .footer_container{
    width: 1250px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .footer_container{
    width: 1100px !important;
  }
}
@media only screen and (max-width: 1097.14px) {
  .footer_container{
    width:880px !important;
  }
}
@media only screen and (max-width: 768px) {
  .footer_container{
    width:680px !important;
  }
}
@media only screen and (max-width: 640px) {
  .footer_container{
    width:580px !important;
  }

}
.footer_box {
    width: 100%;
    // height: 245px;
    padding: 41px 0 42px;
    background: #FFFFFF;
    border-top: 2px solid #8C857B;
    font-family: Source Han Sans CN;

    .footer_container {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left_box {
        width: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        display: flex;
        justify-content: space-between;

        .left_text {

        }

        .footer_title {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border-bottom: 1px solid #FFFFFF;

            .title_items {
                margin-right: 63px;
                margin-bottom: 24px;
                &:nth-child(1) {
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #333333;
                }

                &:nth-child(2) {
                    font-weight: 400;
                }
                &:nth-child(5) {
                    margin-right: 0;
                }
            }
            .cursor_pinter {
                cursor: pointer;
            }
        }

        .record_inner {
            // display: flex;
            // justify-content: space-between;
            margin-top: 25px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            .record_inner_text {
                padding-right: 22px;
                padding-bottom: 22px;
            }
        }
    }

    .mid_box {
        margin-right: -25px;
        .mid_img {
            width: 51px;
            height: 52px;
        }
    }

    .right_box {
        display: flex;
        // 划过弹窗
        .code_item0:hover .gzh_window {
            display: flex;
            transform: scale(1);
        }

        .code_item1:hover .app_window{
            display: flex;
            transform: scale(1);
        }

        .code_item {
            cursor: pointer;
            margin-right: 30px;
            text-align: center;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            .item_img {
                width: 38px;
                height: 31px;
            }

            .item_name {
                text-align: center;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
            }

            .floating_window {
                position: absolute;
                left: -40px;
                top: -180px;
                // width: 150px;
                // height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border-radius: 8px;
                background: #fff;
                border: 1px solid #ddd;
                transition: all .3s ease;
                transform: scale(0);
                .window_img {
                    width: 128px;
                    height: 128px;
                }
            }

            .app_window {
                left: -190px;
                top: -200px;
                padding: 10px;
                transform-origin: 50% 110%;
                .img_box{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-right: 20px;
                    span {
                        padding-top: 10px;
                    }
                }
                .img_box:last-child {
                    padding-right: 0;
                }
            }

            .gzh_window {
                transform-origin: 50% 110%;
                padding: 10px;
            }
        }
    }
}
</style>
