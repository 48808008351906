<template>
    <div class="head_boxs">
        <div class="logo_box">
            <!-- <img src="@/assets/logo.png" class="logo_icon"> -->
            <img src="@/assets/index/lf_logo.png" class="logo_icon">
            <img src="@/assets/index/lf_logo_icon.png" class="logo_right">
        </div>
        <div class="nav_boxs">
            <div v-for="item in headList" :key="item.id" class="nav_items" @click="selectChange(item)">
                <div :class="['nav_name',item.id == selectIdx ? 'nav_active' : null]">{{ item.name }}</div>
                <!-- <img v-show="item.id == selectIdx" src="@/assets/select_idx.png" class="select_idx"> -->
            </div>
        </div>
        <div class="setting_boxs">
<!--            <img src="@/assets/search.png" alt="search" class="search_icon">-->
<!--            <div>EN</div>-->
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"
// 跳路由
import router from "@/router/index";

import { useRoute } from "vue-router";

let selectIdx = ref(1)
// 路由
const route = useRoute();

const headList = ref([
    {
        id: 1,
        routerPath: "/",
        name: '首页'
    },
    {
        id: 2,
        routerPath: "/Cooperation",
        name: '招商合作'
    },
    {
        id: 3,
        routerPath: "/Activity",
        name: '市场活动'
    },
    {
        id: 4,
        routerPath: "/Shop",
        name: '特色店铺'
    },
    {
        id: 5,
        routerPath: "/History",
        name: '隆福历史'
    },
    {
        id: 6,
        routerPath: "/About",
        name: '关于我们'
    },
    {
      id: 7,
      routerPath: "/infoOpen",
      name: '信息公开'
    }
])

function selectChange(item) {
    if (route.fullPath != item.routerPath) {
        sessionStorage.setItem("index", item.id)
        selectIdx.value = item.id
        router.push({
            path: item.routerPath
        })
    }
}

function setSelectIdx() {
    // let idx = headList.value.findIndex(item => item.routerPath == route.fullPath)
    // selectIdx.value = headList.value[idx].id
    if (sessionStorage.getItem('index')) selectIdx.value = sessionStorage.getItem('index')

}
setSelectIdx()
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 2560px) {
  .head_boxs{
    width: 100% !important;
    font-size: 30px !important;
    height:260px !important;
    .nav_name{
      font-size: 30px !important;
    }
  }
}
@media only screen and (min-width: 5760px) {
  .head_boxs{
    font-size: 50px !important;
    height:380px !important;
    .nav_name{
      font-size: 50px !important;
    }
  }
  .logo_icon {
    width: 138px !important;
    height: 135px !important;
  }
  .logo_right {
    width: 78px !important;
    height: 135px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .head_boxs{
    padding: 40px 80px 0 !important;
  }
}
@media only screen and (max-width: 1100px) {
  .head_boxs{
    padding: 40px 100px 0 !important;
    //font-size: 12px !important;
    .nav_name{
      //font-size: 12px !important;
    }
  }
}
@media only screen and (max-width: 768px) {
  .head_boxs{
    //background-color: red !important;
    padding: 40px 30px 0 !important;
    //font-size: 12px !important;
    .nav_name{
      font-size: 16px !important;
    }
  }
}
@media only screen and (max-width: 480px) {
  .head_boxs{
    //background-color: red !important;
    padding: 40px 10px 0 !important;
    //font-size: 10px !important;
    .nav_name{
      font-size: 10px !important;
    }
  }
}
@media only screen and (max-width: 384px) {
  .head_boxs{
    //background-color: red !important;
    padding: 40px 0px 0 !important;
    //font-size: 8px !important;
    .nav_name{
      font-size: 8px !important;
    }
  }
}
.head_boxs {
    width: 100%;
    height: 220.9px;
    font-size: 18px;
    background: #FFDA00;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    padding: 39px 320px 0;
    box-sizing: border-box;
    // justify-content: space-between;

    .logo_box {
        // margin-right: 93px;
        position: relative;
        display: flex;
        justify-content: center;
        .logo_icon {
            width: 88px;
            height: 85px;
            margin-right: 25px;
        }
        .logo_right {
            width: 38px;
            height: 85px;
            //position: absolute;
            //right: 260px;
        }
    }

    .nav_boxs {
        // flex: 1;
        display: flex;
        // height: 70px;
        cursor: pointer;

        .nav_items {
            flex: 1;
            text-align: center;
            position: relative;

            .nav_name {
                line-height: 70px;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #8C837B;
            }

            .select_idx {
                position: absolute;
                left: 50%;
                bottom: 0;
                // transform: (-50%, 0);
                transform: translate(-50%, 0);
                width: 20px;
                height: 13px;
            }

            .nav_active {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
            }
        }
    }

    .setting_boxs {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;

        .search_icon {
            width: 16px;
            height: 16px;
            margin-right: 59px;
        }
    }
}
</style>
