<template>
  <div class="web_boxs">
      <header>
        <headers></headers>
      </header>
      <main class="web_main">
        <router-view :key="router.currentRoute.value.fullPath"></router-view>
      </main>
      <footer class="web_footer">
        <footers></footers>
      </footer>
    </div>
</template>

<script setup>
import { ref, nextTick, onMounted} from "vue";
import headers from "@/components/Header";
import footers from "@/components/Footer"

// 跳路由
import router from "@/router/index";
import axios from 'axios';
let data={
  channel:'1',
  funcName:'网站',
  sys:'1',
  userName:'',
  remark:''
}
axios.post('https://app.longfusi.com.cn/lfs-server/sysLog',data).then(res=>{
  // console.log(res);
})
let render = ref(true)

onMounted(()=>{
  window.addEventListener('resize', reload)
})
const reload = () => {
  render.value = false;
  nextTick(()=>{
    render.value = true;
  })
}
</script>
<style>
*, body {
  padding: 0;
  margin: 0;
  zoom: 1;
}

#app {
  /* width: 1920px;
  margin: 0 auto; */
}
</style>
<style lang="scss" scoped>
.web_boxs {
  // width: 1920px;
  // min-width: 1200px;
  // margin: 0 auto;
  position: relative;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #fff;
  }
  ::-webkit-scrollbar{
    width: 6px;
    background-color: #fff;
  }
  ::-webkit-scrollbar:horizontal{
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8A8A8A;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }

  .web_main {
  }

  .web_footer {
    // position: absolute;
    width: 100%;
    // bottom: 0;
  }
}
</style>
