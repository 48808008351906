import { createApp } from 'vue'
// 引入element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import App from './App.vue'
// import 'lib-flexible'
// import 'lib-flexible/flexible.js'
import "swiper/swiper-bundle.css";
import {monitorZoom} from "./utils/zoom.js";
const m = monitorZoom();
if (window.screen.width * window.devicePixelRatio >= 3840) {
	document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
} else {
	document.body.style.zoom = 100 / Number(m);
}
// 引入路由
import router from './router/index.js'

// 补充适配代码
// import "@/utils/flexible";

const app = createApp(App) // 创建实例
app.use(router)
app.use(ElementPlus,{locale})

app.mount('#app')
