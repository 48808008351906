import {createRouter, createWebHashHistory} from 'vue-router'

const router = createRouter({
    history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [
        {
            path: '/',
            title: "",
            component: () => import('@/page/Index/index'),
        },
        {
            path: '/Cooperation',
            name: "Cooperation",
            title: "招商合作",
            component: () => import('@/page/Cooperation/index'),
        },
        {
            path: '/Activity',
            name: "Activity",
            title: "市场活动",
            component: () => import('@/page/Activity/index'),
        },
        {
            path: '/Activity/detail',
            name: "Detail",
            title: "活动详情",
            component: () => import('@/page/Activity/detail'),
        },
        {
            path: '/Shop',
            name: "Shop",
            title: "特色店铺",
            component: () => import('@/page/Shop/index'),
        },
        {
            path: '/History',
            name: "History",
            title: "隆福历史",
            component: () => import('@/page/History/index'),
        },
        {
            path: '/About',
            name: "About",
            title: "关于我们",
            component: () => import('@/page/About/index'),
        },
        {
            path: '/infoOpen',
            name: "infoOpen",
            title: "信息公开",
            component: () => import('@/page/infoOpen/index'),
        },
        {
            path: '/infoOpen/detail',
            name: "detail",
            title: "信息公开详情",
            component: () => import('@/page/infoOpen/detail'),
        }
    ]
})
/*
 * 路由全局前置守卫
 * */
// router.beforeEach((to, from, next) => {
//     // console.log(to, from);
//     let user = getLocal('userInfo')
//     // to.matched.some(item => {
//     //   console.log(item.meta.needLogin);
//     // })
//     if (to.matched.some(item => item.meta.needLogin)) {
//         // console.log('用户需要登录才行');
//         if (user) { // 判断是否有用户信息 如果有证明用户已经登录
//             return next()
//         } else { // 否则表示用户还未登录 直接进入登录页面
//             // console.log('用户需要登录才行');
//             next('/login')
//         }
//     }
//     setTimeout(() => {
//         window.scrollTo(0, 0)
//         document.body.scrollTop = 0
//         document.documentElement.scrollTop = 0
//     }, 100)
//     next()
//     // return true
// })
export default router
